module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","display":"standalone","crossOrigin":"use-credentials","name":"Fungible Apparel","short_name":"Fungible Apparel","description":"Fungible Apparel","background_color":"#FFFFFF","theme_color":"#155AC3","icon":"src/assets/images/favicon.png","icons":[{"src":"src/assets/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
