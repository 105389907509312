  
import RAF from "./raf"
import Lenis from '@studio-freight/lenis'

interface Scroll  {
  lenis: any | Lenis
  ScrollContainer: HTMLElement
  isSet: boolean
}

class Scroll {

  constructor() {
    this.update = this.update.bind(this)
    this.isSet = false
  }

  init() {
    
    this.lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      direction: 'vertical',
      gestureDirection: 'vertical',
      smooth: true,
      mouseMultiplier: 1,
      // smoothTouch: true,
      // touchMultiplier: 1,
      infinite: false,
    })

    RAF.subscribeScroll(this.update)
    
    this.isSet = true
  }

  disable() {
  }

  update(time) {
    this.lenis.raf(time * 1000)
  }

  reset() {
  }

  resize() {
  }

}

const _instance = new Scroll()
export default _instance