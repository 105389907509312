import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { SkipNavLink } from "components/common/skipNav/SkipNav";
import RESIZE from "utils/eventResize";
import RAF from "utils/raf";
import SCROLL from "utils/scroll";
import * as scss from "./Layout.module.scss";
import { ILayout } from "./Layout.types";
import { ToastContainer } from "react-toastify";

const Layout: React.FC<ILayout> = ({ children, location }) => {
  gsap.config({ force3D: true });

  useEffect(() => {
    RAF.init();
    RESIZE.init();
  }, []);

  useLayoutEffect(() => {
    SCROLL.init();
    // SCROLL.lenis.stop();
  }, []);

  useEffect(() => {
    SCROLL.lenis.scroll = 0;
    window.scrollTo(0, 0);
    SCROLL.lenis.scrollTo(0);
  }, [location]);

  return (
    <main>
      <SkipNavLink />
      <ToastContainer />
      <div className={scss.root}>
        <div className={scss.wrapper}>{children}</div>
      </div>
    </main>
  );
};

export default Layout;
